.navigation + .main {
  width: 100%;
}

@media (max-width: 767px) {
  .navigation + .main {
    height: calc(100vh - 4rem);
  }
}

@media (min-width: 768px) {
  .navigation + .main {
    margin-left: 4rem;
    width: calc(100% - 4rem);
  }

  .navigation.is-expanded + .main {
    margin-left: 17.5rem;
    width: calc(100% - 17.5rem);
  }

  .navigation:not(.is-expanded):hover + .main {
    margin-left: 17.5rem;
    width: calc(100% - 17.5rem);
  }
}
