@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-size: .75rem;
  }

  body {
    font-family: zeitung, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 500;
    color: #1d1550;
  }
}

@layer utilities {
  .hide-scrollbars {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }

  .hide-scrollbars::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

@layer components {
  .form-control {
    @apply bg-white px-4 py-3 rounded-xl block font-medium transition-colors appearance-none disabled:bg-gray-100;
    height: 3.5rem;
  }

  .form-control--sm {
    @apply bg-white px-3 py-2 rounded-xl block font-medium transition-colors appearance-none;
    height: 2.5rem;
  }

  .form-control:not([class*='w-']) {
    @apply w-full;
  }

  .form-control:not([class*='text-']) {
    @apply text-gray-800 disabled:text-gray-500;
  }

  .form-control.rounded-r-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .form-control.rounded-l-0 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .form-control:not(.form-control--shadow) {
    @apply border border-gray-300 hover:border-gray-400 focus:border-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-30;
  }

  .form-control--shadow {
    @apply shadow-md;
  }

  .form-control--no-shadow {
    @apply shadow-none;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
  }

  .form-control.form-control--error {
    @apply border-red-600 focus:ring-red-600 focus:border-red-600 focus:ring-opacity-20;
  }

  .form-control.rounded-b-none {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .form-control.form-control--transparent {
    @apply bg-transparent border-opacity-25;
  }

  .form-control[readonly] {
    @apply bg-gray-200 text-gray-500 cursor-not-allowed;
  }

  select.form-control {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23888' d='M0,9.47A2.94,2.94,0,0,1,5,7.39l11,11,11-11a2.94,2.94,0,1,1,4.16,4.16L18.08,24.61a2.95,2.95,0,0,1-4.16,0L.86,11.55A3,3,0,0,1,0,9.47Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: .75rem auto;
    background-position: calc(100% - 1rem) 50%;
    padding-right: 2.5rem;
  }

  textarea.form-control {
    height: auto;
    min-height: 3.5rem;
  }

  abbr[title] {
    @apply no-underline;
  }

  .form-checkbox,
  .form-radio {
    @apply appearance-none inline-block align-middle select-none flex-shrink-0 rounded h-5 w-5 text-primary bg-white border border-gray-300 transition-colors;
    background-origin: border-box;
  }

  .form-checkbox--empty {
    @apply bg-opacity-0 border-gray-300 border-opacity-25;
  }

  .form-checkbox:checked,
  .form-radio:checked {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23fff' d='M11.05,28.66.44,17A1.67,1.67,0,1,1,2.9,14.76l8.15,9L29.1,3.88a1.67,1.67,0,1,1,2.46,2.25Z'/%3E%3C/svg%3E");
    background-size: 60% auto;
    @apply bg-current bg-center bg-no-repeat border-transparent;
  }

  .form-checkbox:hover,
  .form-radio:hover {
    @apply border-gray-400;
  }

  .form-checkbox:focus,
  .form-radio:focus {
    @apply ring ring-primary ring-opacity-20 outline-none;
  }

  .form-radio {
    @apply rounded-full;
  }

  .btn {
    @apply text-base rounded-full inline-flex items-center leading-tight font-medium transition-colors;
  }

  .btn:not([class*='px-']) {
    @apply px-6;
  }

  .btn:not([class*='py-']) {
    @apply py-4;
  }

  .btn.font-bold {
    font-weight: 700;
  }

  .btn--empty {
    @apply bg-white border hover:bg-gray-100 focus:border-gray-500;
  }

  .btn--default {
    @apply bg-primary-background-dark border hover:bg-primary-background focus:border-gray-500;
  }

  .btn--primary {
    @apply bg-primary border border-primary text-white hover:bg-primary-light focus:bg-primary-light;
  }

  .btn--primary[disabled] {
    @apply bg-gray-200 text-gray-800 border-gray-200 cursor-not-allowed;
  }

  .btn--positive {
    @apply bg-green-500 border border-green-500 text-white hover:bg-green-400 focus:bg-green-400;
  }

  .btn--negative {
    @apply bg-red-500 border border-red-500 text-white hover:bg-red-400 focus:bg-red-400;
  }

  .btn--small {
    @apply px-4 py-2;
  }

  .btn[class*='text-primary'] {
    @apply text-primary;
  }

  .btn[class*='rounded-xl'] {
    @apply rounded-xl;
  }

  .btn-group .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .btn-group .btn:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn--sm, .btn[class*='btn--sm'] {
    @apply text-base py-2;
  }

  .btn--lg, .btn[class*='btn--lg'] {
    @apply text-xl;
  }
}

@media print {
  /* To make sure prints are with colors */
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@import 'styles/react-datepicker.css';
@import 'styles/rc-slider.css';
@import 'styles/mapbox.css';

.subscription-modal-line {
  /*
            2nd-5th Column width   1st column width    Gaps width
    84rem = (4 * 15rem)            + 20rem             + (4 * 1rem)
  */
  min-width: 84rem;
  grid-template-columns: minmax(20rem, 1fr) repeat(auto-fit, minmax(15rem, 1fr));
}

.arrow-down:after {
  content: '';
  top: 100%;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: currentColor;
  border-width: 10px;
  margin-left: -10px;
}

.arrow-down.bg-green-600:after {
  border-top-color: rgba(5, 150, 105, 1);
}

.no-spin-button[type='number']::-webkit-inner-spin-button,
.no-spin-button[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spin-button[type='number'] {
  -moz-appearance: textfield;
}

.shadow-bottom {
  clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);
}

@keyframes ping {
    50% {
        transform: scale(1.2);
        opacity: 0.8;
    }
}

.ping-animation {
    animation: ping 0.5s ease-in-out;
}


